import axios from '@axios'

export default {
  namespaced: true,
  state: {
    calendarOptions: [
    ],
    selectedCalendars: [],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchActivities(ctx, { types }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/activity', {
            params: {
              types,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMyActivities(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/my-activities', {
            params: {
              data,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addActivity(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/activity', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateActivity(ctx, event) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/activity/${event.id}`, event)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteActivity(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/activity/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    //= ====Activity types======
    addActivityType(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/activity-types', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateActivityType(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/activity-types/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteActivityType(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/activity-types/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
